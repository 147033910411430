.root-app {
  display: flex;
  flex-direction: column;
  z-index: 0;
}

.center-container {
  height: 1820px;
  display: flex;
  flex-direction: row;
}

.side-menu {
  display: flex;
  flex-direction: row;
  min-width: 350px;
  z-index: 0;
  overflow-y: auto;
}

.side-menu::-webkit-scrollbar {
  display: block;
  width: 10px;
  height: 8px;
  padding: 10px 10px 10px 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #E5E7EA;
  border-radius: 5px 5px 5px 5px;
}

.body-container {
  width: 100%;
}

.top-bar {
  width: 100%;
  box-shadow: 0px 0px 30px #00000029;
  z-index: 0;
}

@media (max-width: 767px) {
  .center-container{
    flex-direction: column;
    height: 100%;
  }

  .top-bar {
    z-index: 9999;
  }
}

@font-face {
  font-family: 'robotothin';
  src: url('./fonts/roboto-thin-webfont.woff2') format('woff2'),
       url('./fonts/roboto-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotolight';
  src: url('./fonts/roboto-light-webfont.woff2') format('woff2'),
  url('./fonts/roboto-light-webfont.woff') format('woff');
  src: url('./fonts/roboto-light-webfont.woff2') format('woff2'),
       url('./fonts/roboto-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotoregular';
  src: url('./fonts/roboto-regular-webfont.woff2') format('woff2'),
       url('./fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
  src: url('./fonts/roboto-medium-webfont.woff2') format('woff2'),
       url('./fonts/roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'robotobold';
  src: url('./fonts/roboto-bold-webfont.woff2') format('woff2'),
       url('./fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}